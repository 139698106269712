import React from 'react';
import { graphql } from 'gatsby';

import Contact from '../../components/pages/contact';

export default (props) => <Contact {...props} />;

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        fax
        contactPerson
        address
        email
        tel
      }
    }
  }
`;
